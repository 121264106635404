<template>
<div>
    <div v-if="!!detail" class="content custom-width">
        <el-carousel indicator-position="outside" v-if="detail.imageslist.length > 0">
            <el-carousel-item v-for="(item, index) in detail.imageslist" :key="index">
                <img class="imgs" id="oimg" :src="
              item.Images_Url
                ? 'https://www.visasiv.cn' + item.Images_Url
                : '../../static/standImg.jpg'
            " />
            </el-carousel-item>
        </el-carousel>
        <div class="details-wrp">
            <div class="shopInfo">
                <h4>{{merchart.Merchant_Name || '平台自营'}}
                    <span v-if="merchart.Merchant_ID !=0" @click="showShop">进店</span>
                </h4>
                <p>营业时间：{{merchart.Merchant_BussinessHours || '9：00 - 5：00'}}</p>
                <p>联系方式：{{merchart.Merchant_Phone || '18006888696'}}</p>
                <p>地址：{{merchart.Merchant_Address || '浙江省温州市鹿城区大南街道锦春大厦志愿者中心'}}</p>
            </div>
            <div class="detailInfo">
                <div class="mediInfo">
                    <div class="medTit">
                        <text>{{ detail.model.Product_Name }}</text>
                        <div class="price">
                            ¥<span class="lt">{{ detail.model.Product_SellPrice }}</span>
                        </div>
                    </div>
                    <div class="typeslist">
                        <template v-if="colorlist.length >= 2">
                            <div class="typeName">{{ detail.model.Product_ColorAlias }}</div>
                            <div class="typeChoose">
                                <div v-for="(item, index) in colorlist" :key="item.Stock_Color">
                                    <button :class="index == colorIndx ? 'on' : ''" type="default" size="mini" plain :disabled="item.Stock_Total == 0 ? true : false" @click="actBtn('color', index)">
                                        {{ item.Stock_Color }}
                                    </button>
                                </div>
                            </div>
                        </template>
                        <template v-if="sizelist.length >= 2">
                            <div class="typeName">{{ detail.model.Product_SizeAlias }}</div>
                            <div class="typeChoose">
                                <div v-for="(item, index) in sizelist" :key="item.Stock_Size">
                                    <button :class="index == sizeIndx ? 'on' : ''" type="default" size="mini" plain :disabled="item.Stock_Total == 0 ? true : false" @click="actBtn('size', index)">
                                        {{ item.Stock_Size }}
                                    </button>
                                </div>
                            </div>
                        </template>
                        <template v-if="varietylist.length >= 2">
                            <div class="typeName">
                                {{ detail.model.Product_VarietyAlias }}
                            </div>
                            <div class="typeChoose">
                                <div v-for="(item, index) in varietylist" :key="item.Stock_Variety">
                                    <button :class="index == varietyIndx ? 'on' : ''" type="default" size="mini" plain :disabled="item.Stock_Total == 0 ? true : false" @click="actBtn('variety', index)">
                                        {{ item.Stock_Variety }}
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="mediWrap">
                        <div class="price">
                            <label class="fs">单价: <text>{{ Stock_SellPrice || detail.model.Product_SellPrice }}</text> 元</label>
                        </div>
                        <div>
                            <div class="cartCount">
                                <!-- <i class="el-icon-minus" color="#f97a0a" size="25" @tap="countMinus"></i> -->
                                <span>{{ count }}</span>
                                <!-- <i class="el-icon-plus" color="#f97a0a" size="25" @tap="countPlus"></i> -->
                            </div>
                            <div class="stockTal">库存：{{ stockTal }}</div>
                        </div>
                    </div>
                    <el-affix position="top" :offset="20" class="detailInfo">
                        <el-button type="success" @click="addToCart">加入购物车</el-button>
                        <el-button type="primary" @click="justBuy">立即购买</el-button>
                    </el-affix>
                </div>

                <div class="states" v-if="Latitude!=0&&longitude!=0">
                    <div class="tit flxbtw">
                        <label>开始时间</label>
                        <label class="statuAct">{{ activeStatu }}</label>
                    </div>
                    <div class="mediceInfoCont flxbtw">
                        <label>{{ detail.model.Product_FlashStart }}</label>
                        <a class="poi" :href="
                'https://apis.map.qq.com/uri/v1/marker?marker=coord:' +
                Latitude +
                ',' +
                Longitude +
                ';title:' +
                detail.model.Product_Name +
                ';&referer=DYKBZ-BT6W3-V5W3X-YVURI-73257-7QBF5'
              ">
                            <i class="el-icon-location" color="#FA1C24"></i>查看活动举办地点
                        </a>
                    </div>
                </div>
                <div class="notice_text">
                    PS:请获悉，活动产品一律在购买成功后，客服会电话通知最近一期举办的具体时间地点和注意事项。
                </div>
                <div class="states" v-if="detail.model.Product_Brief!==''">
                    <div class="tit">兑换需知</div>
                    <div class="mediceInfoCont">
                        <div v-html="detail.model.Product_Brief"></div>
                    </div>
                </div>
                <div class="mediceInfo">
                    <div class="tit">商品信息</div>
                    <div class="mediceInfoCont">
                        <div v-html="detail.model.Product_Detail"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-backtop :bottom="40">
        <div class="bbk">
            回到<br>顶部
        </div>
    </el-backtop>
</div>
</template>

<script>
let app = null;
export default {
    data() {
        return {
            Latitude: "",
            Longitude: "",
            markers: [{
                title: "活动点",
                latitude: "",
                longitude: "",
                label: {
                    content: "活动地点",
                    color: "#FF6E1D",
                    fontSize: 14,
                },
            }, ],
            merchart: "",
            Product_ID: 0,
            pid: 0,
            count: 1,
            fee: 0,
            stockTal: 0,
            activeStatu: "",
            varietyIndx: -1,
            colorIndx: -1,
            sizeIndx: -1,
            colorlist: [],
            sizelist: [],
            varietylist: [],
            mapShow: false,
            domin: "",
            cartShow: false,
            indicatorDots: true,
            autoplay: true,
            heit: "150px",
            interval: 5000,
            duration: 600,
            postData: {
                productid: 0,
                color: "",
                size: "",
                variety: "",
            },
            odetail: null,
            detail: null,
            options: [{
                icon: "cart",
                text: "购物车",
                info: 0,
            }, ],
            stockid: 0,
            Stock_Total: 0,
            Stock_SellPrice: 0,
            buttonGroup: [{
                    text: "加入购物车",
                    backgroundColor: "#ff0000",
                    color: "#fff",
                },
                {
                    text: "立即购买",
                    backgroundColor: "#F97A0A",
                    color: "#fff",
                },
            ],
        };
    },
    created() {
        app = this.$.appContext.app;
        this.domin = app.domin;
        console.log(app.domin);
    },

    mounted() {
        this.pid = this.$route.params.id;
        this.getMediceDetail(this.pid);
    },
    watch: {
        $route(to, from) {
            this.getMediceDetail(to.params.id)
        }
    },
    methods: {
        showShop(){
            localStorage.setItem('shopInfo',JSON.stringify(this.merchart))
            this.$router.push({
                path:'/shop/'+this.merchart.Merchant_ID
            })
        },
        showMap() {
            this.mapShow = true;
        },
        //跳转到购物车页面
        leftClick(e) {
            console.log(e);
            uni.navigateTo({
                url: "../productCart/productCart",
            });
        },

        addToCart() {
            app.wpost({
                url: "/Ajax/WordWelfare/addtocart",
                data: {
                    stockid: this.stockid,
                    count: this.count,
                },
                succ: (data) => {
                    this.Stock_Total = data.stock.Stock_Total;
                    this.$message("加入成功")
                },
            });
        },
        countPlus: function () {
            if (this.stockid == 0) {
                uni.showToast({
                    title: "请先选择产品对应规格!",
                    icon: "none",
                });
                return false;
            }
            if (this.Stock_Total > this.count) {
                this.count += 1;
            } else {
                uni.showToast({
                    title: "产品库存有限!",
                    icon: "none",
                });
                return false;
            }
        },
        countMinus: function () {
            if (this.stockid == 0) {
                uni.showToast({
                    title: "请先选择产品对应规格!",
                    icon: "none",
                });
                return false;
            }
            if (this.count > 1) this.count -= 1;
        },
        // 立即购买
        justBuy() {
            // 需要传到购买支付页的内容

            if (sessionStorage.getItem("loginState") != 1) {
                this.$message("请先登录，再尝试购买")
                return false
            }

            let item = {
                count: this.count,
                stockid: this.stockid,
                image: this.detail.imageslist[0],
                sellPrice: this.Stock_SellPrice || this.detail.model.Product_SellPrice,
                name: String(this.detail.model.Product_Name),
                stock: {
                    Stock_Color: this.detail.stock.Stock_Color,
                    Stock_Variety: this.detail.stock.Stock_Variety,
                    Stock_Size: this.detail.stock.Stock_Size,
                },
                Freight: this.detail.model.Product_Freight,
            };
            sessionStorage.setItem("product", JSON.stringify(item))
            console.log("item:", item);
            this.$router.push({
                path: "/productOrderin"
            })
        },
        actBtn(type, index) {
            this.count = 1;
            this[type + "Indx"] = index;
            this.postData.productid = this.pid;
            this.postData[type] =
                this.detail[type + "list"][index][
                    "Stock_" + type.substring(0, 1).toUpperCase() + type.substring(1)
                ];
            let varr = ["color", "size", "variety"];
            varr.map((item) => {
                console.log(this.detail[item + "list"]);
                if (this.detail[item + "list"].length == 1) {
                    this.postData[item] =
                        this.detail[item + "list"][0][
                            "Stock_" + item.substring(0, 1).toUpperCase() + item.substring(1)
                        ];
                }
            });
            this.getStock(this.postData);
        },
        getStock(postData) {
            // 拉取后端对应标签库存

            app.wpost({
                url: "/Ajax/WordWelfare.ashx?type=getstocklist",
                data: postData,
                succ: (data) => {
                    console.log("data:", data);
                    this.varietylist = data.varietylist;
                    this.sizelist = data.sizelist;
                    this.colorlist = data.colorlist;
                    if (data.stock.Stock_ID !== 0) {
                        this.stockid = data.stock.Stock_ID;
                        this.stockTal = this.Stock_Total = data.stock.Stock_Total;
                        this.Stock_SellPrice = data.stock.Stock_SellPrice;
                    }
                },
            });
        },
        // 获取药品详情
        getMediceDetail(id) {
            app.wpost({
                url: "/Ajax/WordWelfare/getproductmodel",
                data: {
                    id: id,
                },
                succ: (data) => {
                    console.log("getproductmodel:", data);
                    let mm = data.model.Product_Detail.replace(/src=\"\.\./ig, 'src=\"https://www.visasiv.cn')
                    // console.log("mm",mm)
                    this.detail = data;
                    this.merchart = data.merchant;
                    this.detail.model.Product_Detail = mm
                    this.Stock_Total = data.stock.Stock_Total;
                    this.Stock_SellPrice = data.stock.Stock_SellPrice;
                    this.stockid = data.stock.Stock_ID;
                    this.varietylist = data.varietylist;
                    this.sizelist = data.sizelist;
                    this.colorlist = data.colorlist;
                    this.stockTal = data.stock.Stock_Total;

                    let start = data.model.Product_FlashStart.replace(/-/g, "/");
                    let end = data.model.Product_FlashEnd.replace(/-/g, "/");

                    this.Longitude = this.detail.model.Product_Longitude;
                    this.Latitude = this.detail.model.Product_Latitude;

                    this.markers[0].longitude = this.Longitude;
                    this.markers[0].latitude = this.Latitude;
                    console.log(this.Longitude, this.Latitude);

                    let nowMiu = Date.now();
                    let startMiu = Date.parse(new Date(start));
                    let endMiu = Date.parse(new Date(end));

                    if (nowMiu >= endMiu) {
                        this.activeStatu = "已结束";
                    } else if (nowMiu < startMiu) {
                        this.activeStatu = "未开始";
                    } else if (nowMiu < endMiu && nowMiu >= startMiu) {
                        this.activeStatu = "进行中";
                    }
                },
            });
        },
    },
};
</script>

<style>
.notice_text{
    color: #1f96f8;
    font-size: 12px;
    margin-left: 12px;
    font-weight: bold;
}
.bbk {
    height: 100%;
    width: 100%;
    background-color: #f2f5f6;
    box-shadow: 0 0 6px rgba(0, 0, 0, .12);
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #1989fa;
}

.details-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
}

.shopInfo span {
    color: #1f96f8;
    border: 1px solid #1f96f8;
    border-radius: 2px;
    padding: 2px 3px;

}

.shopInfo span:hover {
    background-color: #1f96f8;
    color: #fff;
}

.shopInfo {
    box-sizing: border-box;
    padding: 10px;
    width: 220px;
    font-size: 12px;
    border: 1px solid #dedede
}

.shopInfo img {
    width: 100%;
    border: 1px solid #dedede;
}

.detailInfo {
    width: calc(100% - 220px);
}

nav {
    margin-bottom: 0 !important;
}

.imgs {
    height: 100%;
    margin: 0 auto;
}

.el-affix {
    text-align: right;
    width: 98%;
    margin-bottom: 10px;
    margin: 10px auto;
}

.el-carousel {
    text-align: center;
}

.content {
    margin: 0 auto;
    box-shadow: 0 0 2px #ccc;
}

.mapBg {
    background-color: rgba(0, 0, 0, 0.78);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
}

.mapClsBtn {
    position: absolute;
    bottom: 30vh;
    left: 50%;
    transform: translateX(-50%);
}

.map {
    width: 97%;
    height: 50vh;
    position: absolute;
    top: 7%;
    left: 1.5%;
}

.statuAct {
    color: #f97a0a;
    font-size: 12px;
}

.uni-goods-nav {
    position: fixed;
    z-index: 99;
    left: 0;
    bottom: 10px;
    width: 100%;
}

.fs {
    font-size: 13px;
}

.fs text {
    font-size: 20px;
    font-weight: bold;
    margin: 0 3px;
    color: #f97a0a;
}

.typeChoose {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.poi {
    font-size: 13px;
    font-weight: normal;
    color: #666;
    text-shadow: 0 0 3px #ccc;
}

.typeChoose button {
    margin: 3px;
}

.typeChoose button.on {
    color: #fff;
    background-color: #f97a0a;
    border: 1px solid #f97a0a;
}

.typeslist {
    padding: 12px;
}

.typeName {
    font-weight: bold;
    font-size: 14px;
    line-height: 2;
}

.cartBtn {
    background-color: #f97a0a;
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 0 10px;

    line-height: 37px;
    border-radius: 20px;
}

.gouIcon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.priceWrp {
    background-color: #555;
    height: 35px;
    line-height: 35px;
    border-radius: 19px;
    padding: 2px 8px;
    font-size: 13px;
}

.imgShow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flxbtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gethings .priceWrp {
    background-color: #f97a0a;
    color: #fff;
}

.gethings .tit01,
.gethings .tit02 {
    color: #fff;
}

.cartIner .cartCount text {
    margin: 0 8px;
}

.cart0 {
    width: 36px;
    height: 36px;
    margin-right: 6px;
}

.buyIner {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    height: 60px;
    background-color: #333;
    position: fixed;
    z-index: 77;
    bottom: 0;
}

.checkIntro {
    margin: 10px 0;
    border: 1px solid #ccc;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 17px;
    border-radius: 20px;
}

.moreInfo {
    padding: 12px;
    color: #999;
    font-size: 11px;
}

.ritarr {
    float: right;
}

.states {
    background-color: #fff;
    padding: 2px 12px;
    margin: 12px 0;
}

.intrDcWrp {
    background-color: #fff;
    margin: 12px 0;
    padding: 2px 12px;
}

.introCont {
    font-size: 12px;
}

.introCont text {
    color: #666;
}

.comments {
    padding: 12px;
    font-size: 15px;
    font-weight: bold;
    background-color: #fff;
}

.comments text {
    font-size: 12px;
    color: #999;
}

.mediceInfoCont {
    padding: 12px 0;
    font-size: 12px;
}

.mediceInfo .moreCheck {
    font-size: 15px;
    text-align: center;
    padding: 12px;
}

.mediceInfo {
    margin: 12px 0;
    background-color: #fff;
    padding: 2px 12px;
}

.mediceInfo .tit {
    border-bottom: 1px solid #ccc;
}

.states .tit,
.intrDcWrp .tit,
.mediceInfo .tit {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0;
    text-align: left;
}

.mediceInfo .mediceInfoCont,
.states .mediceInfoCont {
    width: 80%;
    margin: 0 auto;
    max-width: 700px;
}

.statesWrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.stateOne {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 33.333%;
    margin: 3px 0;
    color: #666;
    font-size: 12px;
}

.swiper,
.swiper image {
    width: 100%;
}

.medTit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 12px;
    font-size: 17px;
    font-weight: bold;
}

.mediWrap {
    padding: 2px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mediInfo {
    padding: 20px 0;
}

.mediInfo,
.swiper {
    background-color: #fff;
}

.price text.lt {
    font-size: 21px;
    color: #e62d56;
    margin: 0 8px 0 6px;
}

.stockTal {
    font-size: 12px;
    text-align: center;
    color: #999;
}

.price text.fs {
    font-size: 12px;
}

.cartCount {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 17px;
    color: #333;
    background-color: #fff;
}

.cartCount text {
    margin: 0 8px;
}
</style>
